<template>
    <div class="container">
      
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        
      };
    },
    mounted() {
      this.aaa()
    },
    methods: {
     aaa(){
        console.log(this.$route.params)
     }
    },
  };
  </script>
  
  <style lang="less" scoped>
  .container {
    box-sizing: border-box;
    padding-top: 50px;
    background: #fff;
  }
  .btn-box {
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
  }
  .btn-confirm {
    width: 100%;
    height: 48px;
    background-color: rgba(#3ca7fa, 0.6);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    cursor: pointer;
    &.active {
      background: #3ca7fa;
    }
  }
  .sms-login-form {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 0px;
    background-color: white;
    box-sizing: border-box;
    padding: 30px 30px 0 30px;
    .sms-login-title {
      width: 100%;
      height: 24px;
      font-size: 24px;
      font-weight: 500;
      color: #171923;
      line-height: 24px;
      box-sizing: border-box;
      margin-bottom: 30px;
    }
  
    .item {
      width: 100%;
      height: auto;
      float: left;
      box-sizing: border-box;
      padding: 6px 0;
      display: flex;
      border-bottom: 1px solid #f4faff;
      margin-bottom: 50px;
  
      .name {
        width: auto;
        height: auto;
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-right: 25px;
      }
  
      .input {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        box-sizing: border-box;
        align-items: center;
        .input-text {
          max-width: 200px;
          height: 36px;
          float: left;
          box-sizing: border-box;
          outline: none;
          color: #333333;
          font-size: 16px;
          border: none;
        }
      }
  
      .captcha {
        width: 90px;
        height: 36px;
        padding-top: 0px;
        margin-left: 19px;
        img {
          width: 90px;
          display: inline-block;
          overflow: hidden;
          position: relative;
        }
      }
  
      .buttons {
        width: auto;
        height: 40px;
        margin-left: 15px;
  
        .send-sms-button {
          display: inline-block;
          width: 80px;
          height: 30px;
          margin-top: 5px;
          line-height: 30px;
          border-radius: 5px;
          text-align: center;
          color: white;
          font-size: 13px;
          background-color: #3ca7fa;
  
          &:active {
            background-color: rgba(#3ca7fa, 0.9);
          }
        }
      }
    }
  }
  
  .login-button-box {
    width: 100%;
    height: auto;
    float: left;
  
    .login-password-way {
      display: block;
      width: 100%;
      height: auto;
      margin-top: 30px;
      margin-bottom: 35px;
      text-align: center;
      font-size: 14px;
      font-weight: 400;
      color: #3ca7fa;
    }
  }
  
  .user-info {
    width: 100%;
    height: auto;
    float: left;
    margin-top: 100px;
    box-sizing: border-box;
    text-align: center;
  
    .avatar,
    .nickname {
      width: 100%;
      height: auto;
      float: left;
      margin-bottom: 15px;
      font-size: 15px;
      color: #333333;
  
      .avatar-img {
        display: inline-block;
      }
    }
  }
  
  .wechat-mini-login-button-box {
    width: 100%;
    height: auto;
    float: left;
    box-sizing: border-box;
    padding: 15px;
  
    .login-button {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      background-color: #3ca7fa;
      color: white;
      font-size: 15px;
      line-height: 40px;
    }
  }
  
  .login-other-way {
    width: 100%;
    float: left;
    height: 48px;
    box-sizing: border-box;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 120px;
  
    .auth-login-divider {
      background-color: #f8f8f8 !important;
    }
  
    .socialite-box {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 48px;
      float: left;
      text-align: center;
  
      .socialite-login-item {
        display: inline-block;
        width: 48px;
        height: 48px;
        border-radius: 24px;
        line-height: 48px;
        text-align: center;
        color: white;
        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
  </style>
  
  <style>
  #page {
    background-color: #f8f8f8;
  }
  </style>
  